import Vue from "vue";
import Router from "vue-router";


Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      redirect: "/login",
      component: () => import("@/views/Body/Blank_layout"),
       children: [
        {
          name: "login",
          path: "login",
          component: () => import("@/views/Login_auth"),
        }
      ] 
    },
    {
        path: "/dashboard",
        component: () => import("@/views/full-layout/FullLayout"),
        children: [
          {
            name: "Order",
            path: "/",
           // component: () => import("@/views/Body/ContentList"),
            component: () => import("@/views/Body/Order_list"),
             meta: {
              requiresAuth: true ,
            }, 
          }, 
          {
            name: "Order",
            path: "/Order_list/:id/:title/:Rid",
            component: () => import("@/views/Body/ContentList"),
             meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "Order Edit",
            path: "/Order_edit_list/:id/:title/:Rid",
            component: () => import("@/views/Body/editContentList"),
             meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "Add Menu",
            path: "/add_menu",
            component: () => import("@/views/Menu/Add_item"),
            meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "Report",
            path: "/All_report",
            component: () => import("@/views/Menu/Report/All_report"),
             meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "General Setting",
            path: "/general_setting",
            component: () => import("@/views/Menu/Setting/general_setting"),
             meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "Feedback ",
            path: "/feedback_report",
            component: () => import("@/views/Menu/Feedback/feedback_report"),
             meta: {
              requiresAuth: true ,
            }, 
          }, 
          {
            name: "Notification ",
            path: "/Notification",
            component: () => import("@/views/Menu/Notification/Notification_index"),
             meta: {
              requiresAuth: true ,
            }, 
          },
          {
            name: "Inventory ",
            path: "/inventory",
            component: () => import("@/views/Menu/Inventory/inventory_index"),
            meta: {
              requiresAuth: true ,
            },
          },
          {
            name: "Security ",
            path: "/Security",
            component: () => import("@/views/Menu/Security/Security_index"),
            meta: {
              requiresAuth: true ,
            },
          },
          {
            name: "Profile ",
            path: "/profile",
            component: () => import("@/views/Menu/profile_index"),
            meta: {
              requiresAuth: true ,
            },
          },
          {
            name: "Income & Expense ",
            path: "/income_expense",
            component: () => import("@/views/Menu/Income/Income_expense"),
            meta: {
              requiresAuth: true ,
            },
          },
        ]
    },
    {
      name: "send_feedback",
      path: "/send_feedback",
      component: () => import("@/views/pages/send_feedback"),
    },
    
  ],
});

router.beforeResolve((to, from, next) => {

  document.title = process.env.VUE_APP_NAME;
  
    if (!to.name) {
      if (localStorage.getItem('authenticated')) {
  
        next();
      } else {
  
        localStorage.clear();
        next({ name: 'login' })
      }
    }
  
    if (to.matched.some(record => record.meta.AdminAuth)) {
      if(localStorage.getItem('authenticated'))
      {
        var role_type = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
        if (role_type === 'admin') {
          next();
        } else {
          next({ name: 'Dashboard' })
        }
      }
     else
     {
      localStorage.clear();
        next({ name: 'login' })
     }
      }
      else  if (to.matched.some(record => record.meta.VendorAuth)) {
        if(localStorage.getItem('authenticated'))
        {
          var role_type_v = JSON.parse(localStorage.getItem("loggedUserData")).role_type;
          if (role_type_v === 'vendor') {
            next();
          } else {
            next({ name: 'Order' })
          }
        }
       else
       {
        localStorage.clear();
          next({ name: 'login' })
       }
        }
   else if (to.matched.some(record => record.meta.requiresAuth)) {
      if (localStorage.getItem('authenticated')) {
        next();
      } else {
        localStorage.clear();
        next({ name: 'login' })
      }
    }
    else {
      if (localStorage.getItem('authenticated')) {
        if (to.name == 'account_verify') {
          next()
        } else {
          next({ name: 'Order' })
        }
  
      }
      else {
        next()
      }
    }
  

  });

  

export default router;
