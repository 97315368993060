import Vue from 'vue'
import App from './App.vue'
import './index.css';
import { BootstrapVue,BootstrapVueIcons } from 'bootstrap-vue'

import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import router from "./Router/router";
import './assets/css/style.css';
import 'animate.css';
import  "./interceptor.js";

import DateRangePicker from 'vue2-daterange-picker'

import 'vue2-daterange-picker/dist/vue2-daterange-picker.css'

import store from "./store/Store";

import Vue2Editor from "vue2-editor";

import VueMarqueeSlider from 'vue-marquee-slider';
Vue.use(VueMarqueeSlider)

import VueToastr from "vue-toastr";
// use plugin
Vue.use(VueToastr);

Vue.use(Vue2Editor);

Vue.use(BootstrapVue)
Vue.use(BootstrapVueIcons)
Vue.use(DateRangePicker)

import VueQRCodeComponent from 'vue-qrcode-component'
Vue.component('qr-code', VueQRCodeComponent)

import CKEditor from '@ckeditor/ckeditor5-vue2';

Vue.use( CKEditor );

Vue.config.productionTip = false

new Vue({  store, router, render: (h) => h(App),scrollBehavior() {
  return new Promise((resolve) => {
    resolve({ left: 0, top: 0 })
  })
} }).$mount("#app");

window.addEventListener('message', (e) => {
  if (e.data && typeof e.data === 'string' && e.data.match(/webpackHotUpdate/)) {
    console.log('hot reload happened')
    console.clear()
  }
})
